import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

import { useDebounce } from '../../hooks';
import { withTranslation } from '../../../i18n';
import { getEmbedCode } from '../../api';
import useWorkspace from '../../hooks/useWorkspace';

import { SwitchWithTitle } from '../../switch';
import { initialState, reducer, TYPE_BASIC, TYPE_SPA } from './reducer';
import TilesSelector from '../../tilesSelector';
import BackgroundVisibleIcon from './images/video-visible.svg';
import BackgroundTransparentIcon from './images/video-transparent.svg';
import BackgroundTileIcon from './images/video-tile-background.png';
import ColorPickerInput from '../../inputs/ColorPickerInput';
import { rgbaObjToString } from '../../../utils';
import { Label } from '../../globals';
import Dropdown from '../../dropdown';
import Box from '@mui/material/Box';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import Stack from '@mui/material/Stack';
import KeyboardArrowDownOutlinedIcon from '@mui/icons-material/KeyboardArrowDownOutlined';
import CodeTextarea from './CodeTextarea';
import SimpleSelect from '../../select';

const DIMENSIONS = [
  ['fixed', 'Fixed (375 x 660 px)'],
  ['responsive', 'Adjusted (fit-to-content)'],
];
const SHADOWS = [
  ['shadowOff', 'No drop shadow'],
  ['shadowOn', 'Add a drop shadow'],
];
const dropdownItems = [
  { title: TYPE_BASIC, value: TYPE_BASIC },
  { title: TYPE_SPA, value: TYPE_SPA },
];
const selectOptions = [
  { value: 'iframe', label: 'iframe' },
  { value: 'html', label: 'HTML' },
];

const backgroundOptions = [
  {
    name: 'background_type',
    value: 'visible',
    label: 'Visible',
    testId: 'EmbedSection/videoBackgroundVisible',
    icon: BackgroundVisibleIcon,
  },
  {
    name: 'background_type',
    value: 'transparent',
    label: 'Transparent',
    testId: 'EmbedSection/videoBackgroundTransparent',
    icon: BackgroundTransparentIcon,
  },
];

const makeParams = ({
  dimension,
  shadow,
  background,
  embedSpecificGoal,
  showLoadingSpinner,
  loadingSpinnerColor,
  experienceId,
  type,
}) => ({
  embed: {
    experience_uuid: experienceId,
    goal_by_embed: embedSpecificGoal,
    shadow: shadow === 'shadowOn',
    transparent: background === 'transparent',
    responsive: dimension === 'responsive',
    show_loading_spinner: showLoadingSpinner,
    loading_spinner_color: loadingSpinnerColor,
    type,
  },
});

const DEBOUNCE_TIMEOUT = 500;
const scriptTagRegex = /<script[^>]*>[\s\S]*?<\/script>/i;

function EmbedSection({
  experienceId,
  t,
  htmlScriptUrl,
  osCountryElementUrl,
  osFileUploadElementUrl,
  osSignatureElementUrl,
  experienceDataFields,
}) {
  const workspaceId = useWorkspace();

  const [state, dispatch] = React.useReducer(reducer, initialState);
  const [type, setType] = React.useState(selectOptions[0]);
  const { params, isLoading, embedCode, widgetType } = state;
  const { dimension, shadow } = params;
  const debouncedParams = useDebounce(params, DEBOUNCE_TIMEOUT);

  const changeParam = (paramName) => (value) =>
    dispatch({ type: 'changeParam', payload: { [paramName]: value } });

  const handleRadioChange = (onChange) => (e) => {
    const input = e.target.previousElementSibling;

    onChange(input.value);
  };

  const handleSelectType = (value) => {
    dispatch({ type: 'updateWidgetType', payload: { type: value } });
  };

  const renderRadioOptions = (optionName, currentValue, labels, onChange) =>
    labels.map(([name, label], i) => (
      <div key={i} className="widget-form__input_radio-item">
        <input
          type="radio"
          name={optionName}
          value={name}
          checked={currentValue === name}
          readOnly
        />
        <label
          className="form__radio-label"
          htmlFor={name}
          onClick={handleRadioChange(onChange)}
        >
          <span />
          {label}
        </label>
      </div>
    ));

  const customDataFields = React.useMemo(() => {
    return [
      { name: 'country', url: osCountryElementUrl },
      { name: 'upload', url: osFileUploadElementUrl },
      { name: 'signature', url: osSignatureElementUrl },
    ];
  }, [osCountryElementUrl, osFileUploadElementUrl]);

  const htmlScript = () => {
    const baseScript = (url) =>
      `<script defer src="${url}?workspace-id=${workspaceId}"></script>`;

    let script = baseScript(htmlScriptUrl);

    customDataFields.forEach(({ name, url }) => {
      if (experienceDataFields.includes(name)) {
        script += `\n  ${baseScript(url)}`;
      }
    });

    return script;
  };

  useEffect(() => {
    getEmbedCode(makeParams({ ...debouncedParams, experienceId })).then(
      ({ code }) => {
        dispatch({ type: 'updateEmbedCode', payload: { code } });
      },
    );
  }, [debouncedParams, experienceId]);

  const handleLoadingSpinnerColorChange = (value) => {
    changeParam('loadingSpinnerColor')(rgbaObjToString(value));
  };

  const changeEmbedTypeParam = (value) => {
    changeParam('type')(value);
  };

  const separatedEmbedCode = React.useMemo(() => {
    const scriptCodeMatch = embedCode.match(scriptTagRegex);
    return {
      head: scriptCodeMatch ? scriptCodeMatch[0] : '',
      body: embedCode.replace(scriptTagRegex, ''),
    };
  }, [embedCode]);

  const handleTypeChange = (obj) => {
    if (obj.value === 'html') {
      changeEmbedTypeParam('html');
    } else {
      changeEmbedTypeParam('');
    }
    setType(obj);
  };

  return (
    <>
      <div className="popup__header">
        Options
        <Box sx={{ my: 2, fontSize: 16 }}>
          <SimpleSelect
            options={selectOptions}
            value={type}
            isSearchable={false}
            classNamePrefix="react-select__embed-type"
            onChange={handleTypeChange}
          />
        </Box>
      </div>
      {type.value === 'iframe' ? (
        <Box>
          <form>
            <div className="widget-form__input_wrapper">
              <label className="form__label">BACKGROUND</label>
              <div className="popup__hint">
                "Transparent" only applies to color backgrounds. Your uploaded
                images and videos will always be visible.
              </div>
              <TilesSelector
                defaultValue="visible"
                options={backgroundOptions}
                onChange={changeParam('background')}
                tileBackground={BackgroundTileIcon}
              />
            </div>
            <div className="widget-form__input_wrapper">
              <label className="form__label">DROP SHADOW</label>
              <div className="popup__hint">
                This will add a light drop shadow behind the iframe.
              </div>
              {renderRadioOptions(
                'shadow',
                shadow,
                SHADOWS,
                changeParam('shadow'),
              )}
            </div>
            <div className="widget-form__input_wrapper">
              <label className="form__label">DIMENSIONS</label>
              <div className="popup__hint">
                {t('EmbedSection.dimensionsHint')}
              </div>
              {renderRadioOptions(
                'dimension',
                dimension,
                DIMENSIONS,
                changeParam('dimension'),
              )}
            </div>
          </form>
          <div className="popup__header mb-8">Advanced Settings</div>
          <div className="popup__hint">
            <SwitchWithTitle
              title="Embed-specific goals"
              onChange={(e) =>
                changeParam('embedSpecificGoal')(e.target.checked)
              }
              data-testid="EmbedSection/EmbedSpecificGoalSwitcher"
              noborder="true"
            />
            {t('EmbedSection.settingsHint')}
          </div>
          {params.dimension === 'fixed' && (
            <SwitchWithTitle
              title="Show loading spinner"
              subtitle={t('EmbedSection.loadingSpinnerHint')}
              checked={params.showLoadingSpinner}
              onChange={(e) =>
                changeParam('showLoadingSpinner')(e.target.checked)
              }
              data-testid="EmbedSection/ShowLoadingSpinnerSwitcher"
              noborder="true"
            >
              <Label>Spinner color</Label>
              <ColorPickerInput
                color={params.loadingSpinnerColor}
                onChange={handleLoadingSpinnerColorChange}
                onChangeComplete={handleLoadingSpinnerColorChange}
                withoutColorsBar
                withAlpha
              />
            </SwitchWithTitle>
          )}
          <div className="popup__header">
            <div className="popup__header--left">Code</div>
            <Box sx={{ display: 'inline-block', float: 'right' }}>
              <Dropdown
                actionButton={
                  <Box
                    sx={{
                      position: 'relative',
                      p: '0.375rem 1.75rem 0.375rem 0.75rem',
                      backgroundColor: 'var(--background-secondary)',
                      border: '1px solid var(--border-color-primary)',
                      borderRadius: '0.25rem',
                      opacity: 0.85,
                      fontWeight: 500,
                      fontSize: '0.75rem',
                      lineHeight: '1rem',
                      color: 'var(--font-color-primary)',
                    }}
                  >
                    {widgetType}
                    <KeyboardArrowDownOutlinedIcon
                      sx={{
                        position: 'absolute',
                        right: '5px',
                        fontSize: 18,
                      }}
                    />
                  </Box>
                }
                menuItems={dropdownItems}
                onSelection={handleSelectType}
                listStyle={{ width: '150px' }}
              />
            </Box>
          </div>
          <div className="popup__subheader">{widgetType}</div>
          {widgetType === TYPE_BASIC ? (
            <>
              <div className="popup__hint">
                {t('EmbedSection.widgetType.basic.description')}
              </div>
              <CodeTextarea
                header={t('EmbedSection.widgetType.basic.title')}
                hint={t('EmbedSection.widgetType.basic.hint')}
                isLoading={isLoading}
                value={embedCode}
              />
            </>
          ) : (
            <>
              <div className="popup__hint">
                {t('EmbedSection.widgetType.spa.description')}
              </div>
              <CodeTextarea
                header={t('EmbedSection.widgetType.spa.head.title')}
                hint={t('EmbedSection.widgetType.spa.head.hint')}
                isLoading={isLoading}
                value={separatedEmbedCode.head}
              />
              <CodeTextarea
                header={t('EmbedSection.widgetType.spa.body.title')}
                hint={t('EmbedSection.widgetType.spa.body.hint')}
                isLoading={isLoading}
                value={separatedEmbedCode.body}
              />
            </>
          )}
        </Box>
      ) : (
        <>
          {(experienceDataFields.includes('upload') || experienceDataFields.includes('signature')) && (
            <Alert
              sx={{
                mb: 4,
                fontSize: 14,
                fontFamily: 'inherit',
                color: 'var(--font-color-primary)',
              }}
              severity="warning"
            >
              <AlertTitle
                sx={{
                  mb: 2,
                  fontSize: 16,
                  fontWeight: 500,
                  fontFamily: 'inherit',
                }}
              >
                Make sure your website is authorized for file uploads!
              </AlertTitle>
              <Stack gap={2}>
                <Box>
                  The website hosting your form must be listed as an authorized origin in Workspace Settings.
                </Box>
              </Stack>
            </Alert>
          )}
          <CodeTextarea
            header={t('EmbedSection.embedType.html.script.title')}
            minHeight="84px"
            rows="3"
            value={htmlScript()}
          />
          <CodeTextarea
            header={t('EmbedSection.embedType.html.basic.title')}
            hint={t('EmbedSection.embedType.html.basic.hint')}
            isLoading={isLoading}
            value={embedCode}
          />
        </>
      )}
    </>
  );
}

EmbedSection.propTypes = {
  experienceId: PropTypes.string.isRequired,
  t: PropTypes.func.isRequired,
};

export { EmbedSection };
export default withTranslation()(EmbedSection);
